import * as React from 'react';
import { SVGProps, memo } from 'react';

function DevicesIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={49} height={48} fill="none" {...props} data-testid="devicesIcon">
      <path
        fill="#007C92"
        fillRule="evenodd"
        d="M14.417 9a3.25 3.25 0 0 1 3.25-3.25h17.25V39a3.25 3.25 0 0 1-3.25 3.25h-14a3.25 3.25 0 0 1-3.25-3.25V9Zm3.25-4.75A4.75 4.75 0 0 0 12.917 9v30a4.75 4.75 0 0 0 4.75 4.75h14a4.75 4.75 0 0 0 4.75-4.75V4.25h-18.75Zm4 34a.75.75 0 1 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(DevicesIcon);
