import axios from 'axios';
import { AxiosRequestTimeout } from './PortalConfig';

export const baseURL = `${import.meta.env.VITE_API_URL}${import.meta.env.VITE_ENVIRONMENT === 'local' ? '/api' : ''}`;

const Api = axios.create({
  baseURL,
  withCredentials: true,
  timeout: AxiosRequestTimeout,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Requested-With, X-Auth-Token, content-type',
    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    'X-XSRF-TOKEN': document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1'),
  },
});

Api.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`;
  // eslint-disable-next-line no-param-reassign
  config.headers['X-XSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
  return config;
});

/**
 * 401 handler
 */
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        switch (error.response.status) {
          case 401:
            // eslint-disable-next-line no-console
            console.log('AuthContext: Session is invalid!');
            localStorage.removeItem('jwt');
            // TODO: navigate a session expired page first?
            window.location.href = `${import.meta.env.VITE_SAML_URL}`;
            break;
          default:
            return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
    return error;
  },
);
export default Api;
