import * as React from 'react';
import { memo, SVGProps } from 'react';

function CloseIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} data-testid="closeIcon">
      <path
        stroke={props?.stroke ? props.stroke : '#0D0D0D'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.5 18.5 13-13m0 13-13-13"
      />
    </svg>
  );
}
export default memo(CloseIcon);
