import * as React from 'react';
import { SVGProps, memo } from 'react';

function FilterIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} data-testid="filterIcon">
      <path stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5v8l-3 3v-11l-8-8h19l-8 8Z" />
    </svg>
  );
}
export default memo(FilterIcon);
