import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TerminateConnectionDateFormat } from '../../../../../configs/DateConfig';
import useSnackBar from '../../../../../hooks/useSnackBar/useSnackBar';
import { TerminateConnectionRequest } from '../../../../../models/UserDevicesModels';
import UsersService from '../../../../../services/Users/UsersService';

function useTerminateConnection(onClose: () => void, onTerminateConnectionSuccess: () => void) {
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Adjusts the terminate connection request payload
   * @param request
   */
  const terminateConnectionRequestAdapter = (request: TerminateConnectionRequest) => ({
    ...request,
    terminationDate: request.terminationDate
      ? DateTime.fromISO(request.terminationDate).toFormat(TerminateConnectionDateFormat)
      : DateTime.now().plus({ days: 31 }).toFormat(TerminateConnectionDateFormat),
  });

  /**
   * Terminate connection request
   * @param request
   */
  const terminateConnection = (request: TerminateConnectionRequest) => {
    setIsLoading(true);
    UsersService.api
      .terminateConnection(terminateConnectionRequestAdapter(request))
      .then(
        () => {
          snackBar.showSnackBar(t('pages.userDetails.terminateConnectionDrawer.terminateConnectionSuccess'), 'success');
          onTerminateConnectionSuccess();
          onClose();
        },
        () => {
          snackBar.showSnackBar(t('pages.userDetails.terminateConnectionDrawer.terminateConnectionError'), 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { terminateConnection, isLoading };
}

export default useTerminateConnection;
