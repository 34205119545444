import * as React from 'react';
import { SVGProps, memo } from 'react';

function ArrowLeft(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} data-testid="arrowLeft">
      <g stroke={props?.stroke ? props.stroke : '#333'} strokeLinecap="round" strokeLinejoin="round">
        <path d="M11 3.5 2.5 12l8.5 8.5M2.5 12h19" />
      </g>
    </svg>
  );
}
export default memo(ArrowLeft);
